import { client } from '~/api/client'
import { postWebAffiliatesV1, Schemas } from '~/api/gen'
import { isNetworkError } from '~/lib/isNetworkError'

export const postAffiliates = async (
  accessToken: string | undefined,
  affiliatesRequest: Schemas.post_web_affiliates_v1_request
) => {
  try {
    await postWebAffiliatesV1(
      client,
      { requestBody: affiliatesRequest },
      { token: accessToken }
    )
  } catch (e) {
    if (isNetworkError(e)) return undefined
    if (e instanceof Error && e.name === 'AbortError') return undefined

    throw new Error(`Failed to post affiliate ${String(e)}`)
  }
}
