import axios from 'axios'

let mockWorkerInitialized = false

type Fetch = typeof globalThis.fetch

// mswがNode.js18系を未サポートなため、mswを使う場合はfetchをmswFetchに差し替える
// ref. https://github.com/mswjs/msw/issues/1388
// eslint-disable-next-line complexity
export const fetch = (async (resource, init = {}) => {
  if (process.env.NEXT_PUBLIC_USE_MSW === 'true' && !mockWorkerInitialized) {
    if (typeof window === 'undefined' || process.env.VITEST) {
      const { mockServer } = await import('~/mocks/server')
      mockServer.listen()
    } else {
      const { mockWorker } = await import('~/mocks/browser')
      await mockWorker.start().catch(console.error)
    }
    mockWorkerInitialized = true
  }

  const url =
    typeof resource === 'string'
      ? resource // URL文字列の場合
      : 'href' in resource // 型表現上はブラウザのURLとNode.jsのURLが別物なので、hrefがあるかで判定
        ? resource.href // URLオブジェクトの場合
        : undefined // Requestオブジェクトの場合
  if (url === undefined) {
    // MSWを利用する場合にしか用いなくclient.ts経由で利用される前提なため
    // Requestオブジェクトはサポートしない
    throw new Error('Request object is not supported')
  }
  const result = await axios({
    url,
    method: init.method,
    data: init.body,
    headers: Object.fromEntries(new Headers(init.headers)),
    signal: init.signal ?? undefined,
    validateStatus: () => true, // fetchはステータスコードが200以外でもthrowしない
  })
  // MSWを利用する場合にしか用いなくclient.ts経由で利用される前提なためレスポンスボディはJSON前提
  return new Response(
    result.status === 204 ? null : JSON.stringify(result.data),
    {
      status: result.status,
      statusText: result.statusText,
      headers: result.headers satisfies {
        [key in string]?: string
      } as Record<string, string>,
    }
  )
}) satisfies Fetch
