import Cookies from 'js-cookie'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Schemas } from '~/api/gen'
import { useAccessToken } from '~/hooks/useAccessToken'
import { ScreenIds } from '~/types/screenIds'
import { InitAffiliateContext } from './components'
import { postAffiliates } from './lib/postAffiliates'

type UseReadyAffiliate = (
  callback?: (
    screenId: ScreenIds,
    objectId: number,
    preScreenId: ScreenIds | undefined,
    preObjectId: number
  ) => void
) => {
  isReady: boolean
  screenId: ScreenIds | undefined
  objectId: number
  preScreenId: ScreenIds | undefined
  preObjectId: number
}
export const useReadyAffiliate: UseReadyAffiliate = (callback) => {
  const isReady = useContext(InitAffiliateContext)
  const [screenId, setScreenId] = useState<ScreenIds>()
  const [objectId, setObjectId] = useState<number>(0)
  const [preScreenId, setPreScreenId] = useState<ScreenIds>()
  const [preObjectId, setPreObjectId] = useState<number>(0)

  useEffect(() => {
    if (!isReady) return
    const tmpScreenId = Cookies.get('ScreenID') as ScreenIds
    const tmpObjectId = Number(Cookies.get('ObjectID'))
    const tmpPreScreenId = Cookies.get('PreScreenID') as ScreenIds
    const tmpPreObjectId = Number(Cookies.get('PreObjectID'))
    setScreenId(tmpScreenId)
    setObjectId(tmpObjectId)
    setPreScreenId(tmpPreScreenId)
    setPreObjectId(tmpPreObjectId)
    callback?.(tmpScreenId, tmpObjectId, tmpPreScreenId, tmpPreObjectId)
  }, [isReady, callback])

  return { isReady, screenId, objectId, preScreenId, preObjectId }
}

export const usePostAffiliates = () => {
  const accessToken = useAccessToken()
  const post = useCallback(
    (affiliatesRequest: Schemas.post_web_affiliates_v1_request) => {
      postAffiliates(accessToken ?? undefined, affiliatesRequest)
    },
    [accessToken]
  )

  return {
    postAffiliates: post,
  }
}
