/**
 * @see https://github.com/sindresorhus/is-network-error/blob/7bbfa8be9482ce1427a21fbff60e3ee1650dd091/index.js
 */
const errorMessages = new Set([
  'network error', // Chrome
  'Failed to fetch', // Chrome
  'NetworkError when attempting to fetch resource.', // Firefox
  'The Internet connection appears to be offline.', // Safari 16
  'Load failed', // Safari 17+
  'Network request failed', // `cross-fetch`
  'fetch failed', // Undici (Node.js)
  'terminated', // Undici (Node.js)
])

export const isNetworkError = (error: unknown): boolean => {
  const isValid =
    error instanceof Error &&
    error.name === 'TypeError' &&
    typeof error.message === 'string'

  if (!isValid) {
    return false
  }

  // 冗長と判断したためコメントアウト ref. https://github.com/sindresorhus/is-network-error/issues/7
  // // We do an extra check for Safari 17+ as it has a very generic error message.
  // // Network errors in Safari have no stack.
  // if (error.message === 'Load failed') {
  //   return error.stack === undefined
  // }

  return errorMessages.has(error.message)
}
