import { NEXT_PUBLIC_CONNECT_API_URL, NEXT_PUBLIC_USE_MSW } from '~/lib/env'
import { ApiClient, HttpMethod, ObjectLike, QueryParameters } from './gen'
import { fetch as mswFetch } from './mswFetch'

export type BaseOptions = Readonly<{
  baseUrl: string
  timeout?: number
  fetch?: typeof window.fetch
  headers?: ObjectLike
}>
export type Options = Readonly<
  Partial<BaseOptions> & {
    token?: string
  }
>

export class Client implements ApiClient<Options, Response> {
  constructor(private baseOptions: BaseOptions) {}

  get baseUrl() {
    return this.baseOptions.baseUrl
  }

  // eslint-disable-next-line max-params, complexity
  async request<T, U>(
    httpMethod: HttpMethod,
    url: string,
    headers: ObjectLike,
    requestBody: ObjectLike,
    queryParameters?: QueryParameters,
    options: Options = {}
  ) {
    const {
      baseUrl,
      token,
      timeout,
      fetch = globalThis.fetch,
    } = { ...this.baseOptions, ...options }
    const target = new URL(baseUrl)
    const basePath = target.pathname.replace(/\/$/, '')
    const relativePath = url.replace(/^\//, '')

    target.pathname = `${basePath}/${relativePath}`

    const headersWithToken: ObjectLike = {
      ...this.baseOptions.headers,
      ...headers,
      ...options.headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    }

    if (queryParameters) {
      target.search = Client.queryParametersToString(queryParameters)
    }

    const body =
      httpMethod === 'GET' || httpMethod === 'HEAD'
        ? undefined
        : JSON.stringify(requestBody)

    let abortController: AbortController | undefined
    if (timeout !== undefined && timeout > 0) {
      const controller = new AbortController()
      setTimeout(() => controller.abort(), timeout)
      abortController = controller
    }
    const response = await fetch(target, {
      method: httpMethod,
      headers: headersWithToken,
      body,
      signal: abortController?.signal,
    })
    const json = await response.json().catch(() => undefined)
    if (response.ok) {
      return { type: 'success', data: json as T, raw: response } as const
    }
    return { type: 'error', error: json as U, raw: response } as const
  }

  private static queryParametersToString(queryParameters: QueryParameters) {
    const params = new URLSearchParams()
    for (const [key, { value }] of Object.entries(queryParameters)) {
      if (value === undefined) continue
      if (Array.isArray(value)) {
        const k = key.toString()
        params.delete(k)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        value.forEach((v) => params.append(k, v))
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        params.set(key.toString(), value)
      }
    }
    return params.toString()
  }
}

const baseUrl = NEXT_PUBLIC_CONNECT_API_URL ?? ''
export const client = new Client({
  baseUrl,
  timeout: 5000,
  // HACK: mswがNode.js18系を未サポートなため、mswを使う場合はfetchをmswFetchに差し替える
  // ref. https://github.com/mswjs/msw/issues/1388
  fetch: NEXT_PUBLIC_USE_MSW === 'true' ? mswFetch : undefined,
})

const zendeskBaseUrl = `${NEXT_PUBLIC_CONNECT_API_URL}/zendesk`
export const zendeskClient = new Client({
  baseUrl: zendeskBaseUrl,
  timeout: 5000,
  // HACK: mswがNode.js18系を未サポートなため、mswを使う場合はfetchをmswFetchに差し替える
  // ref. https://github.com/mswjs/msw/issues/1388
  fetch: NEXT_PUBLIC_USE_MSW === 'true' ? mswFetch : undefined,
})
